import React from 'react';
import styles from './Home.module.css';
import { Link } from 'react-router-dom';

export default class Home extends React.Component {

    constructor(props) {
        super(props);
    };

    componentDidMount() {
        if ( this.iOS() ) {
            window.location.href = 'https://apps.apple.com/app/vibe-game/id6478194603';
        }
    };
    
    iOS = () => {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    };

    render() {
        return (
            <div className={styles.rootView}>
                {/* <img src={require('../../assets/animation.gif')} className={styles.background} /> */}
                <div className={styles.background} />
                <div className={styles.backgroundOverlay} />

                <div className={styles.content}>
                    <div className={styles.logoView}>
                        <img className={styles.vibeImage} src={require('../../assets/vibe.png')} />
                        <img className={styles.gameImage} src={require('../../assets/game.png')} />
                    </div>

                    {/* <div className={styles.vibeText}>vibe</div>
                    <div className={styles.gameText}>game</div> */}

                    <a href="https://apps.apple.com/app/vibe-game/id6478194603">
                        <img src={require('../../assets/appstore.png')} className={styles.appstore} />
                    </a>

                    <div className={styles.footer}>
                        <a href='mailto:vibegameapp@gmail.com' className={styles.link}>Help</a>
                        <Link to="/terms" className={styles.link}>Terms</Link>
                        <Link to="/privacy" className={styles.link}>Privacy</Link>
                    </div>
                    <div className={styles.copyright}>© KVK LLC. 2024</div>
                </div>
            </div>
        );
    };
};